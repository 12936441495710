import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

const dev = false;
let baseUrl;

if (dev) {
  baseUrl = "http://localhost:8000";
} else {
  baseUrl = "https://powerful-refuge-95735-e636965d892f.herokuapp.com";
}

const ImageGeneratorApp = () => {
  const [prompt, setPrompt] = useState(
    `photorealistic, best quality, masterpiece, high res

full body shot of an athletic, good looking, white, 46 year old male model, with a light beard, styled dark brown hair.

The man is in the streets of New York City

The man is wearing a black <lora:POLO> polo shirt

The <lora:POLO> polo shirt has four (4) evenly spaced BLACK buttons. 

The <lora:POLO> polo shirt is tucked into black chino jeans, with a black belt.

The <lora:POLO> polo shirt has a placket with a triangular bottom that sits at the middle of the chest.

Posing towards the camera. 
 
 `
  );
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [numOutputs, setNumOutputs] = useState(1);
  const [placeholders, setPlaceholders] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [cameraStyle, setCameraStyle] = useState("Canon 5d Mark IV");
  const [effects, setEffects] = useState("none");
  const [selectedImage, setSelectedImage] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (isGenerating) {
      setPlaceholders(Array(numOutputs).fill(null));
    } else {
      setPlaceholders([]);
    }
  }, [isGenerating, numOutputs]);

  useEffect(() => {
    const fetchSavedImages = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/get-ai-images?id=${encodeURIComponent(id)}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch saved images");
        }
        const data = await response.json();
        setImages(data.images);
      } catch (error) {
        console.error("Error fetching saved images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedImages();
  }, [id]);

  const handleGenerateImages = async () => {
    setIsGenerating(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/generate-image/?prompt=${encodeURIComponent(
          prompt
        )}&num_outputs=${numOutputs}&id=${encodeURIComponent(
          id
        )}&aspect_ratio=${encodeURIComponent(
          aspectRatio
        )}&camera_style=${encodeURIComponent(cameraStyle)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to start image generation");
      }

      const { task_id } = await response.json();

      // Polling function to check task status
      const pollTaskStatus = async (taskId) => {
        const statusResponse = await fetch(
          `${baseUrl}/api/check-image-status/${taskId}`,
          { method: "GET" }
        );
        const statusData = await statusResponse.json();
        if (statusData.status === "completed") {
          setImages((prevImages) => [...statusData.images, ...prevImages]);
          setIsGenerating(false);
        } else if (statusData.status === "processing") {
          // Poll again after a short delay
          setTimeout(() => pollTaskStatus(taskId), 3000);
        } else {
          throw new Error("Image generation failed");
        }
      };

      // Start polling
      pollTaskStatus(task_id);
    } catch (error) {
      console.error("Error generating images:", error);
      setIsGenerating(false);
    }
  };

  const handleEnhanceFace = async (imageUrl) => {
    setIsGenerating(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/generate-image/?prompt=${encodeURIComponent(
          prompt
        )}&num_outputs=${numOutputs}&id=${encodeURIComponent(
          id
        )}&aspect_ratio=${encodeURIComponent(
          aspectRatio
        )}&camera_style=${encodeURIComponent(
          cameraStyle
        )}&enhance_face=true&image_url=${encodeURIComponent(imageUrl)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to start image generation");
      }

      const { task_id } = await response.json();

      // Polling function to check task status
      const pollTaskStatus = async (taskId) => {
        const statusResponse = await fetch(
          `${baseUrl}/api/check-image-status/${taskId}`,
          { method: "GET" }
        );
        const statusData = await statusResponse.json();
        if (statusData.status === "completed") {
          console.log("Enhanced face for image:", statusData.images);
          setImages((prevImages) => [...statusData.images, ...prevImages]);
          setIsGenerating(false);
        } else if (statusData.status === "processing") {
          // Poll again after a short delay
          setTimeout(() => pollTaskStatus(taskId), 3000);
        } else {
          throw new Error("Image generation failed");
        }
      };

      // Start polling
      pollTaskStatus(task_id);
    } catch (error) {
      console.error("Error generating images:", error);
      setIsGenerating(false);
    }
  };

  const getProxiedUrl = (url) =>
    `https://proxy-image-fa8914807e51.herokuapp.com/api/proxy-image?url=${encodeURIComponent(
      url
    )}`;

  const handleDownloadImage = async (imageUrl) => {
    try {
      const response = await fetch(getProxiedUrl(imageUrl));
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "generated-image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100">
      {/* Toolbar */}
      <div className="w-full md:w-1/4 lg:w-1/5 bg-white p-4 md:p-6 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-indigo-700">
          Generate Images
        </h2>
        <div className="space-y-3 md:space-y-4">
          <textarea
            placeholder="Enter your magical prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-vertical"
            rows={4}
            style={{ minHeight: "80px", maxHeight: "200px" }}
          />
          <div className="flex flex-col">
            <label htmlFor="numOutputs" className="text-sm text-gray-600 mb-1">
              Number of Images: {numOutputs}
            </label>
            <input
              type="range"
              id="numOutputs"
              min="1"
              max="4"
              value={numOutputs}
              onChange={(e) => setNumOutputs(parseInt(e.target.value))}
              className="w-full"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="aspectRatio" className="text-sm text-gray-600 mb-1">
              Aspect Ratio:
            </label>
            <select
              id="aspectRatio"
              value={aspectRatio}
              onChange={(e) => setAspectRatio(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="1:1">1:1 (Square)</option>
              <option value="4:3">4:3 (Landscape)</option>
              <option value="16:9">16:9 (Landscape)</option>
              <option value="9:16">9:16 (Portrait)</option>
              <option value="3:4">3:4 (Portrait)</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="cameraStyle" className="text-sm text-gray-600 mb-1">
              Camera Style:
            </label>
            <select
              id="cameraStyle"
              value={cameraStyle}
              onChange={(e) => setCameraStyle(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="Fuifilm X100v">Fuifilm X100v</option>
              <option value="Kodak">Kodak</option>
              <option value="Canon 5d Mark IV">Canon 5d Mark IV</option>
              <option value="Black and White">Black and White</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="effects" className="text-sm text-gray-600 mb-1">
              Effects:
            </label>
            <select
              id="effects"
              value={effects}
              onChange={(e) => setEffects(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="none">None</option>
              <option value="amateur">Amateur Photographer</option>
            </select>
          </div>
          {selectedImage && (
            <div className="flex flex-col">
              <button
                onClick={() => handleEnhanceFace(selectedImage)}
                className="w-full bg-green-600 text-white p-2 rounded-md hover:bg-green-700 transition-colors duration-300 mt-2 flex items-center justify-center"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                Enhance Face
              </button>
            </div>
          )}
          <button
            onClick={handleGenerateImages}
            className={`w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center ${
              isGenerating ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isGenerating}
          >
            {isGenerating ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generating...
              </>
            ) : (
              <>
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
                Generate Images (~3 minutes)
              </>
            )}
          </button>
        </div>
      </div>

      {/* Image Grid */}
      <div className="flex-1 p-4 md:p-8 overflow-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {isLoading ? (
            <div className="col-span-full flex justify-center items-center">
              <svg
                className="animate-spin h-10 w-10 text-indigo-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <>
              {isGenerating &&
                placeholders.map((_, index) => (
                  <div
                    key={`placeholder-${index}`}
                    className="w-full h-40 sm:h-48 md:h-56 lg:h-64 bg-black bg-opacity-10 rounded-lg shadow-md flex items-center justify-center"
                  >
                    <svg
                      className="animate-spin h-8 w-8 text-indigo-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ))}
              {images.map((image, index) => (
                <div
                  key={`image-${index}`}
                  className="transform transition-all duration-300 hover:scale-105 relative"
                >
                  <img
                    src={image.url}
                    alt={`Generated  ${index + 1}`}
                    className="w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
                    onClick={() => {
                      setPrompt(image.prompt);
                      setCameraStyle(image.camera_style);
                      setAspectRatio(image.aspect_ratio);
                      setSelectedImage(image.url);
                    }}
                  />
                  <button
                    onClick={() => handleDownloadImage(image.url)}
                    className="absolute top-2 right-2 bg-white bg-opacity-75 p-2 rounded-full hover:bg-opacity-100 transition-all duration-300"
                  >
                    <FaDownload className="text-indigo-600" />
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageGeneratorApp;
