import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ImageGeneratorApp from "./ImageGenerator";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ImageGeneratorApp />} />
        <Route path="/main" element={<ImageGeneratorApp />}>
          <Route path=":id" element={<ImageGeneratorApp />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
